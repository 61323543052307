import './App.css';
import GalleryContainer from './GalleryContainer';

function App() {
  let images = [
    'https://i.imgur.com/YZ5pUk1.jpg',
    'https://i.imgur.com/VQljbLc.jpg',
    'https://i.imgur.com/NUzuCV2.jpg',
    'https://i.imgur.com/ExKhfAt.jpg',
    'https://i.imgur.com/K4OUbKl.jpg',
    'https://i.imgur.com/vKWCzAn.jpg',
  ];

  return (
    <div className="App">
      <main>
        <section className="py-5 text-center container">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="fw-light">The front page of happiness</h1>
              <p className="lead text-muted">Things that make you go hehe lol</p>
            </div>
          </div>
        </section>
        <div className="bg-light">
          <GalleryContainer images={images} />
        </div>
        <footer className="py-5 my-5">
          <p class="text-center text-muted">Thanks for stopping by.</p>
        </footer>
      </main>
    </div>
  );
}

export default App;
