import React from "react"
import GalleryItem from "./GalleryItem";

export default class GalleryContainer extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="carousel slide py-5" data-bs-interval="4000" data-bs-ride="carousel" data-bs-keyboard="false" data-bs-pause="false">
          <div className="carousel-inner">
            {this.props.images.map((url, idx) => (<GalleryItem key={idx} src={url} is_active={idx === 0} />))}
          </div>
        </div>
      </div>
    );
  }
}