import React from "react";

export default class GalleryItem extends React.Component {
  render() {
    return (
      <div className={'carousel-item' + (this.props.is_active ? ' active' : '')}>
        <img className="d-block w-100" src={this.props.src} alt="" />
      </div>
    );
  }
}